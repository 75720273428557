import { createContext, ReactNode, useContext } from 'react';
import { action, makeAutoObservable, when } from 'mobx';
import * as Sentry from '@sentry/nextjs';
import { enableStaticRendering } from 'mobx-react-lite';

import { IAlert } from '../model/Alerts';
import { ApiResponse, ApiResponseStatus, getRequest, postRequest } from '../util/ApiRequest';

enableStaticRendering(typeof window === 'undefined');

type ConfirmProps = {
  message: ReactNode | ReactNode[];
  title?: string;
  okLabel?: string;
  hideCancel?: boolean;
  cancelLabel?: string;
};

export enum ModalType {
  Message = 'MESSAGE',
  Login = 'LOGIN',
  Signup = 'SIGNUP',
  ForgotPassword = 'Forgot',
  CreateProposal = 'CreateProposal',
}

class Site {
  error?: string | null = null;

  errorTitle?: string | null = null;

  alerts: IAlert[] = [];

  isLoadingAlerts = false;

  private _showAlertsDropdown = false;

  confirmProps: ConfirmProps | null = null;

  private confirmResolve: null | (() => void) = null;

  private confirmReject: null | ((reason?: any) => void) = null;

  modalShowing: ModalType | null = null;

  modalProps?: any;

  constructor() {
    makeAutoObservable(this, {
      setError: action.bound,
      onConfirm: action.bound,
      onConfirmCancel: action.bound,
      showModal: action.bound,
    });
  }

  async loadAlerts() {
    this.isLoadingAlerts = true;
    try {
      const userStore = globalThis.Rose.user;
      if (!userStore.isAuthenticated()) {
        return;
      }
      const result = await getRequest('/user/alerts');
      if (result.status === ApiResponseStatus.Error) {
        console.error(`Error loading alerts: ${result.data}`);
        return;
      }

      this.alerts = result.data.alerts as IAlert[];
    } finally {
      this.isLoadingAlerts = false;
    }
  }

  get showAlertsDropdown(): boolean {
    return this._showAlertsDropdown;
  }

  set showAlertsDropdown(value: boolean) {
    if (value) {
      // noinspection JSIgnoredPromiseFromCall
      this.loadAlerts();
    }
    this._showAlertsDropdown = value;
  }

  clearAlerts() {
    this.alerts = [];
  }

  get unreadCount() {
    if (!this.alerts) {
      return 0;
    }

    let unreadCount = 0;
    if (this.alerts) {
      for (let i = 0; i < this.alerts.length; i++) {
        if (!this.alerts[i].isRead) {
          unreadCount++;
        }
      }
    }

    return unreadCount;
  }

  async markAsRead(id: string) {
    const userStore = globalThis.Rose.user;
    if (!userStore.isAuthenticated()) {
      return;
    }

    const result = await postRequest(`/user/alerts/${id}`);
    if (result.status === ApiResponseStatus.Error) {
      console.error(`Error loading alerts: ${result.data}`);
      return;
    }

    this.alerts = result.data.alerts as IAlert[];
  }

  async markAllAsRead() {
    this.isLoadingAlerts = true;
    try {
      const userStore = globalThis.Rose.user;
      if (!userStore.isAuthenticated()) {
        return;
      }
      const result = await postRequest('/user/alerts/all');
      if (result.status === ApiResponseStatus.Error) {
        console.error(`Error loading alerts: ${result.data}`);
        return;
      }

      this.alerts = result.data.alerts as IAlert[];
    } finally {
      this.isLoadingAlerts = false;
    }
  }

  /**
   * handles error responses from apis because lazy
   * @param message
   */
  setError(message?: unknown, title = 'Error') {
    const t = typeof message;
    if (t === 'string' || message === null || message === undefined) {
      this.error = message as string;
      return;
    }
    // might be an error object
    if (Array.isArray(message)) {
      // take the first one
      // eslint-disable-next-line prefer-destructuring
      if (typeof message[0] === 'object' && message[0].msg) {
        // validation error
        this.error = `${message[0].msg}: ${message[0].param} = ${message[0].value}`;
        return;
      }

      // eslint-disable-next-line prefer-destructuring
      this.error = message[0];
      return;
    }

    this.error = String(message);
    this.errorTitle = title;
  }

  setErrorTitle(title?: string | null) {
    this.errorTitle = title;
  }

  callWithErrorHandling = async (request: () => Promise<ApiResponse>) => {
    try {
      const response = await request();
      if (response.status === ApiResponseStatus.Error)
        this.setError(`Could not perform the requested action at this time: ${response.data}`);
      return response;
    } catch (error) {
      this.setError('Something went wrong. Could not perform the requested action');
      console.error(error);
      Sentry.captureException(error);
    }
  };

  showModal(modalType: ModalType | null | false | undefined, props?: any): Promise<void> {
    this.modalShowing = modalType || null;
    this.modalProps = props;

    if (this.modalShowing === null) return Promise.resolve();

    // return a promise that'll resolve when the modal is dismissed
    return new Promise<void>((resolve) => {
      when(
        () => this.modalShowing === null,
        () => {
          resolve();
        }
      );
    });
  }

  /**
   * change the active modal but preserve props
   */
  changeModal(modalType: ModalType | null | false | undefined): Promise<void> {
    return this.showModal(modalType, this.modalProps);
  }

  /**
   * require authentication. Resolves immediately if already auth'ed, rejects if dismissed
   */
  authenticate(
    modalType: ModalType | null | false | undefined = ModalType.Signup,
    props?: any
  ): Promise<void> {
    this.modalShowing = modalType || null;
    this.modalProps = props;

    if (Rose.user.isAuthenticated() || this.modalShowing === null) {
      return Promise.resolve();
    }

    // return a promise that'll resolve when the modal is dismissed
    return new Promise<void>((resolve, reject) => {
      when(
        () => this.modalShowing === null,
        () => {
          if (Rose.user.isAuthenticated()) {
            resolve();
          } else {
            reject();
          }
        }
      );
    });
  }

  confirm(props: ConfirmProps) {
    return new Promise<boolean>((resolve) => {
      this.confirmProps = props;
      this.confirmResolve = () => resolve(true);
      this.confirmReject = () => resolve(false);
    });
  }

  onConfirm() {
    this.confirmProps = null;
    if (this.confirmResolve) {
      this.confirmResolve();
      this.confirmResolve = null;
    }
    this.confirmReject = null;
  }

  onConfirmCancel() {
    this.confirmProps = null;
    this.confirmResolve = null;
    if (this.confirmReject) {
      this.confirmReject();
      this.confirmReject = null;
    }
  }
}

export const siteStore = new Site();
export type ISiteStore = typeof siteStore;

globalThis.Rose = globalThis.Rose || {};
globalThis.Rose.site = siteStore;

export const SiteStoreContext = createContext<ISiteStore>(siteStore);

export const useSiteStore = (): ISiteStore => useContext<ISiteStore>(SiteStoreContext);
