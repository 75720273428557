import { IUserInfo } from '../model/UserInfo';
import { ApiResponseStatus, getRequest } from '../util/ApiRequest';
import { action, makeAutoObservable } from 'mobx';

const SERVER = 'server';
type CacheEntry = {
  // when cached
  at: number;
  // api response
  user: IUserInfo;
};
const MAX_CACHED = 20;
const MAX_TIME_MS = 600000; // 10 minutes in ms
const pending = Object();

export class UserCache {
  private cache: Record<string, CacheEntry> = {};

  private url: string;

  constructor() {
    this.url = this.getLocation();
    makeAutoObservable(this, {
      prune: action.bound,
    });

    if (this.url !== SERVER) {
      setInterval(this.prune, 60000 /* one minute */);
    }
  }

  public prune(): void {
    const now = Date.now();

    Object.keys(this.cache).forEach((uid) => {
      const entry = this.cache[uid];
      if (!entry) {
        return;
      }
      if (now - entry.at > MAX_TIME_MS) {
        delete this.cache[uid];
      }
    });

    let keys: string[];
    // eslint-disable-next-line no-cond-assign
    while ((keys = Object.keys(this.cache)).length > MAX_CACHED) {
      // js preserves insertion order so just delete the first one
      delete this.cache[keys[0]];
    }
  }

  private getLocation(): string {
    if (typeof window === 'undefined') {
      this.url = SERVER;
    } else {
      this.url = window.location.href;
    }
    return this.url;
  }

  /**
   * get an entry. if not present it'll fill it
   *
   * does not return a promise, you should use the computed getter
   * @param userId
   */
  get = (userId: string): IUserInfo | undefined => {
    const entry = this.cache[userId];
    if (entry === pending) return undefined;
    if (entry) return entry.user;
    this.load(userId);
    return undefined;
  };

  load = async (userId: string): Promise<IUserInfo> => {
    this.cache[userId] = pending;
    try {
      const response = await getRequest(`/users/${userId}`);
      if (response.status === ApiResponseStatus.Success) {
        this.cache[userId] = {
          at: Date.now(),
          user: response.data as IUserInfo,
        };
      }
      return response.data as IUserInfo;
    } catch (e: any) {
      delete this.cache[userId];
      throw e;
    }
  };
}
