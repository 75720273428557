import { createContext } from 'react';
import { Noto_Sans, Roboto } from 'next/font/google';
import classNames from 'classnames';

const notoSans = Noto_Sans({
  subsets: ['latin'],
  display: 'swap',
  variable: '--body-font',
});
const roboto = Roboto({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--header-font',
});

export const themeDefault = {
  variables: {
    '--body-font': notoSans.variable,
    '--header-font': roboto.variable,
  },
  classNames: classNames(notoSans.className, roboto.className),
};

export const Theme = createContext(themeDefault);
