// Ported from https://github.com/django/django/blob/main/django/core/signing.py

import { createHash, createHmac } from 'crypto';

export const divmod = (x: number, y: number) => [Math.floor(x / y), x % y];

export function saltedHmac(keySalt: string, value: string, secret: string, algorithm: string) {
  // Ported from https://github.com/django/django/blob/main/django/utils/crypto.py
  const key = createHash(algorithm)
    .update(keySalt + secret)
    .digest();
  const hmac = createHmac(algorithm, key);
  return hmac.update(value);
}

export function base64Hmac(salt: string, value: string, key: string, algorithm: string) {
  return Buffer.from(saltedHmac(salt, value, key, algorithm).digest()).toString('base64url');
}
