// Ported from https://github.com/django/django/blob/main/django/core/signing.py
// See https://docs.djangoproject.com/en/4.2/topics/signing/ for documentation

import { timingSafeEqual } from 'crypto';
import { base64Hmac } from './util';
import { BadSignatureError } from './badSignatureError';

const SEP_UNSAFE: RegExp = /^[A-z0-9-_=]*$/;

export class Signer {
  key: string;

  fallbackKeys: string[];

  sep: string;

  algorithm: string;

  salt: string;

  constructor(
    key: string,
    sep?: string,
    salt?: string,
    algorithm?: string,
    fallbackKeys?: string[]
  ) {
    this.key = key;
    this.fallbackKeys = fallbackKeys ?? [];
    this.sep = sep ?? ':';
    if (this.sep.match(SEP_UNSAFE)) {
      throw new Error(
        `Unsafe Signer separator: ${this.sep} (cannot be empty or consist of only A-z0-9-_=)`
      );
    }
    this.salt = salt ?? 'django.core.signing.Signer';
    this.algorithm = algorithm ?? 'sha256';
  }

  signature(value: string, key?: string) {
    if (!key) {
      key = this.key;
    }

    return base64Hmac(`${this.salt}signer`, value, key, this.algorithm);
  }

  sign(value: string) {
    const sig = this.signature(value);
    return `${value}${this.sep}${sig}`;
  }

  unsign(signedValue: string) {
    if (signedValue.indexOf(this.sep) === -1) {
      throw new BadSignatureError(`No "${this.sep}" found in value`);
    }

    const parts = signedValue.split(this.sep);
    const sig = parts[parts.length - 1];
    const value = parts.slice(0, -1).join(this.sep);
    for (const key of [this.key].concat(this.fallbackKeys)) {
      const compareSig = this.signature(value, key);
      if (sig.length !== compareSig.length) {
        // Ok to bail early if the entered value isn't the correct hash length.
        // See https://github.com/nodejs/node/issues/17178
        throw new BadSignatureError(`Signature "${sig}" does not match`);
      }

      if (
        timingSafeEqual(Buffer.from(sig, 'utf8'), Buffer.from(this.signature(value, key), 'utf8'))
      ) {
        return value;
      }
    }
    throw new BadSignatureError(`Signature "${sig}" does not match`);
  }
}
