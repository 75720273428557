import { useUserStore } from '../../store/user';
import { useEffect } from 'react';
import posthog from 'posthog-js';
import { analytics } from '../../store/analytics';

export function PosthogInit() {
  const user = useUserStore();
  useEffect(() => {
    const key = globalThis.Rose.config.NEXT_PUBLIC_POSTHOG_KEY;
    if (key) {
      posthog.init(key, {
        // page view is manually captured to account for client side nav
        capture_pageview: false,
        api_host: 'https://app.posthog.com',
        loaded: (inst) => {
          // for access to posthog.debug()
          (window as any).posthog = inst;
          const info = user.getUserInfo();
          if (user.isAuthenticated() && info) {
            analytics.onLogin(info);
          }
        },
        session_recording: {
          maskAllInputs: true,
        },
        person_profiles: 'identified_only',
      });
    }
    // if user changes it'll be on login or out and will be
    // handled in the user store (don't fire again)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
