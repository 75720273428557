import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import styles from './styles.module.scss';

export const RoseErrorBoundary: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  useEffect(() => {
    (window as any).Sentry = Sentry;
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={
        <div className={styles.top}>
          <h1>Error</h1>
          <div className={styles.message}>
            <p>Sorry, an error has occurred.</p>
            <p>
              Please{' '}
              <button className="btn link" type="button" onClick={() => window.location.reload()}>
                reload
              </button>{' '}
              this page to try again.
            </p>
          </div>
        </div>
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
