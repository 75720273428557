// Ported from https://github.com/django/django/blob/main/django/core/signing.py
import { divmod } from './util';

const BASE62_ALPHABET = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export function b62Encode(msg: number) {
  if (msg === 0) {
    return '0';
  }

  let sign = '';
  if (msg < 0) {
    sign = '-';
    msg = Math.abs(msg);
  }

  let remainder = null;
  let encoded: string = '';
  while (msg > 0) {
    [msg, remainder] = divmod(msg, 62);
    encoded = BASE62_ALPHABET[remainder] + encoded;
  }

  return sign + encoded;
}

export function b62Decode(msg: string) {
  if (msg === '0') {
    return 0;
  }

  let sign = 1;
  if (msg[0] === '-') {
    msg = msg.slice(1);
    sign = -1;
  }

  let decoded = 0;
  for (let i = 0; i < msg.length; i++) {
    decoded = decoded * 62 + BASE62_ALPHABET.indexOf(msg[i]);
  }
  return sign * decoded;
}
