// Ported from https://github.com/django/django/blob/main/django/core/signing.py
// See https://docs.djangoproject.com/en/4.2/topics/signing/ for documentation

import { Signer } from './signer';
import { b62Decode, b62Encode } from './base62';
import { SignatureExpiredError } from './signatureExpiredError';

export class TimestampSigner extends Signer {
  timestamp() {
    return b62Encode(new Date().getTime());
  }

  sign(value: string) {
    const tsValue = `${value}${this.sep}${this.timestamp()}`;
    return super.sign(tsValue);
  }

  unsign(value: string, maxAge?: number) {
    const parts = super.unsign(value).split(this.sep);
    const ts = b62Decode(parts[parts.length - 1]);
    value = parts.slice(0, -1).join(this.sep);
    if (maxAge) {
      const age = new Date().getTime() - ts;
      if (age > maxAge) {
        throw new SignatureExpiredError(`Signature age ${age} > ${maxAge} seconds`);
      }
    }
    return value;
  }
}
