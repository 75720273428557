import React, { createContext, ReactNode, useContext } from 'react';
import { IUserInfo, IUserInfoUnion, UserActivationStatus, UserRole } from '../model/UserInfo';
import { AuthState, AuthStateSuccessful } from '../types/AuthState';
import {
  ApiResponse,
  ApiResponseStatus,
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from '../util/ApiRequest';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { siteStore } from './site';
import { NameValueDict } from '../types/FormTypes';
import { FieldValueType } from '../types/Field';
import { analytics } from './analytics';

class UserStore {
  authState: AuthState = { auth: false, cached: false };

  user: IUserInfo | undefined;

  // tracking state for first time load
  initialLoad: boolean = true;

  constructor(initialState: AuthState) {
    this.authState = initialState;
    makeAutoObservable(this, {
      saveUser: action.bound,
      setAuthData: action.bound,
      initialLoad: false,
    });

    if (this.authState.auth || this.authState.cached) {
      // noinspection JSIgnoredPromiseFromCall
      this.refresh();
    }
  }

  get profile(): IUserInfo | undefined {
    return this.user;
  }

  login = async (values: NameValueDict<FieldValueType>): Promise<ApiResponse> => {
    const response = await postRequest('/auth', values);
    runInAction(() => {
      if (response.status === ApiResponseStatus.Success) {
        this.setAuthData(response.data);
        analytics.onLogin(this.getUserInfo() as IUserInfo);
      }
    });
    return response;
  };

  signUp = async (values: NameValueDict<FieldValueType>): Promise<ApiResponse> => {
    if (this.isAuthenticated()) {
      return { status: ApiResponseStatus.Error, data: 'Already logged in' };
    }
    const response = await postRequest('/users', values);
    runInAction(() => {
      if (response.status === ApiResponseStatus.Success) {
        this.setAuthData(response.data);
        analytics.onLogin(this.getUserInfo() as IUserInfo);
      }
    });
    return response;
  };

  update = async (values: NameValueDict<FieldValueType> | FormData): Promise<ApiResponse> => {
    const response = await patchRequest('/users/me', values);
    runInAction(() => {
      const info = this.getUserInfo();
      if (response.status === ApiResponseStatus.Success && info !== null) {
        this.setUserInfo(response.data);
        analytics.onLogin(info);
      }
    });
    return response;
  };

  verify = async (verificationCode: string): Promise<ApiResponse> => {
    const response = await postRequest(`/users/verify/code`, {
      verificationCode,
    });
    runInAction(() => {
      if (response.status === ApiResponseStatus.Success) {
        this.setAuthData(response.data);
        analytics.event('verified');
      }
    });
    return response;
  };

  /**
   * for manager roles, set an impersonation token to act on the behalf of their own clients.
   */
  impersonate = async (clientId: string): Promise<ApiResponse> => {
    const response = await postRequest(`/auth/${clientId}/proxy`, {});
    runInAction(() => {
      if (response.status === ApiResponseStatus.Success) {
        this.setAuthData(response.data);
      }
    });
    return response;
  };

  /**
   * for manager roles, stop impersonating and reassign the original userid
   */
  stopImpersonating = async (): Promise<ApiResponse> => {
    const response = await deleteRequest(`/auth/${this.getUserId()}/proxy`);
    runInAction(() => {
      if (response.status === ApiResponseStatus.Success) {
        this.setAuthData(response.data);
      }
    });
    return response;
  };

  refresh = async (): Promise<ApiResponse> =>
    getRequest(`/users/me`).then(
      action((response) => {
        if (response.status === ApiResponseStatus.Success) {
          response.data.cached = false;
          this.authState = response.data;
          this.user = response.data.user;
        }
        return response;
      })
    );

  private updateUser(user: IUserInfoUnion) {
    if (this.authState.auth) {
      this.authState.cached = false;
      this.authState.user = user;
    }
  }

  logout = async (): Promise<ApiResponse> => {
    try {
      const response = await deleteRequest('/auth');
      localStorage.removeItem('user');
      analytics.onLogout();
      this.setAuthData({ auth: false, cached: false });
      window.location.assign('/');
      return response;
    } catch (error: any) {
      siteStore.setErrorTitle('Error');
      siteStore.setError(`Logout unsuccessful: ${error.message}`);
      return { data: 'Unsuccessful', status: ApiResponseStatus.Error };
    } finally {
      runInAction(() => {
        this.authState = { auth: false, cached: false };
        siteStore.clearAlerts();
      });
    }
  };

  // public for cypress tests
  setAuthData(data: AuthState): void {
    if (!data) console.trace('setAuthData called with null data');
    this.authState = data;

    if (data.cached) {
      // noinspection JSIgnoredPromiseFromCall
      this.refresh();
    }

    if (data.auth) {
      // noinspection JSIgnoredPromiseFromCall
      siteStore.loadAlerts();
    }
  }

  getUserId(): string {
    if (this.authState.auth && this.authState.user?.id) {
      return this.authState.user.id;
    }
    return '';
  }

  getUserRole: () => UserRole | undefined = () => {
    if (this.authState.auth && this.authState.user) return this.authState.user.role;
  };

  getUserInfo(): IUserInfo | null {
    if (this.authState.auth) {
      return this.authState.user;
    }
    return null;
  }

  /**
   * if this is an impersonation login, the string returned will be the userid of the manager
   */
  getManagerId(): string | null {
    if (this.authState.auth) {
      return this.authState.managerId || null;
    }
    return null;
  }

  getName(): string {
    if (!this.authState.auth) {
      throw new Error("Can't get name, not authenticated");
    }

    const { user } = this.authState;
    return [user.firstName || '', user.lastName || ''].join(' ');
  }

  setUserInfo = (user: IUserInfoUnion) => {
    if (this.authState.auth) {
      this.authState.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    }
  };

  isAuthenticated = (): boolean => this.authState.auth && Boolean(this.authState.user.id);

  isActivated = (): boolean =>
    this.authState.auth &&
    (this.authState.user.status === UserActivationStatus.Active || Boolean(this.getManagerId()));

  hasRole = (userRole: UserRole) => this.authState.auth && this.authState.user.role === userRole;

  /**
   * Is the current user a provider.
   */
  isProvider = (): boolean => this.hasRole(UserRole.Provider);

  hasValidAddress = (): boolean => {
    const address = this.getUserInfo()?.address;
    if (!address) return false;
    return Boolean(address.streetAddress && address.city && address.state && address.zipCode);
  };

  sendActivationEmail(): Promise<ApiResponse> {
    const userId = this.getUserId();
    return postRequest(`/users/${userId}/activation`, {
      returnTo: `${window.location.pathname}?${window.location.search}`,
    }).then(
      action((response) => {
        if (response.status === ApiResponseStatus.Success) {
          // logged in response?
          if (response.data.auth) {
            // already verified
            this.setAuthData(response.data);
          }
        }
        return response;
      })
    );
  }

  saveUser(otherId: string) {
    const myId = this.getUserId();
    if (!myId) throw new Error('must be logged in');
    return postRequest<AuthStateSuccessful>(`/users/${myId}/toggle-save/${otherId}`, {}).then(
      action((response) => {
        if (response.status === ApiResponseStatus.Success) {
          this.updateUser(response.data);
        }
        return true;
      })
    );
  }

  getCsrfToken = async (): Promise<string> => {
    if (!this.authState.csrfToken) {
      const response = await getRequest('/getcsrftoken');
      runInAction(() => {
        if (response.status === ApiResponseStatus.Success) {
          this.updateCsrfToken(response.data.csrf);
        }
      });
    }

    if (this.authState.csrfToken) {
      return this.authState.csrfToken;
    }

    return '';
  };

  applyCouponCode = async (coupon: string): Promise<ApiResponse> =>
    postRequest('/users/apply-coupon-code', { coupon });

  private updateCsrfToken(csrfToken: string) {
    this.authState.csrfToken = csrfToken;
  }
}

// mutable is only for server
// eslint-disable-next-line import/no-mutable-exports
let userStore = new UserStore({ auth: false, cached: false });
export type IUserStore = typeof userStore;

globalThis.Rose.user = userStore;

const User = createContext<IUserStore>(userStore);

export const useUserStore = (): IUserStore => useContext<IUserStore>(User);

type AuthProps = {
  initialState?: AuthState;
  children: ReactNode;
};

const AuthProvider: React.FC<React.PropsWithChildren<AuthProps>> = ({ initialState, children }) => {
  if (typeof window === 'undefined') {
    // server needs a new instance every time
    userStore = new UserStore(initialState || { auth: false, cached: false });
  } else if (initialState?.auth) {
    userStore.setAuthData(initialState);
  } else if (!initialState && userStore.initialLoad) {
    userStore.refresh();
  }
  userStore.initialLoad = false;
  return <User.Provider value={userStore}>{children}</User.Provider>;
};

export { AuthProvider };
