import { ApiResponseStatus, getRequest } from '../util/ApiRequest';
import { action, makeAutoObservable } from 'mobx';
import { IProposal } from '../model/Proposal';

const SERVER = 'server';
type CacheEntry = {
  // when cached
  at: number;
  // api response
  proposal: IProposal;
};
const MAX_CACHED = 20;
const MAX_TIME_MS = 600000; // 10 minutes in ms
const pending = Object();

export class ProposalCache {
  private cache: Record<string, CacheEntry> = {};

  private url: string;

  constructor() {
    this.url = this.getLocation();
    makeAutoObservable(this, {
      prune: action.bound,
    });

    if (this.url !== SERVER) {
      setInterval(this.prune, 60000 /* one minute */);
    }
  }

  public prune(): void {
    const now = Date.now();

    Object.keys(this.cache).forEach((uid) => {
      const entry = this.cache[uid];
      if (!entry) {
        return;
      }
      if (now - entry.at > MAX_TIME_MS) {
        delete this.cache[uid];
      }
    });

    let keys: string[];
    // eslint-disable-next-line no-cond-assign
    while ((keys = Object.keys(this.cache)).length > MAX_CACHED) {
      // js preserves insertion order so just delete the first one
      delete this.cache[keys[0]];
    }
  }

  private getLocation(): string {
    if (typeof window === 'undefined') {
      this.url = SERVER;
    } else {
      this.url = window.location.href;
    }
    return this.url;
  }

  /**
   * get an entry. if not present it'll fill it
   *
   * does not return a promise, you should use the computed getter
   */
  get = (proposalId: string): IProposal | undefined => {
    const entry = this.cache[proposalId];
    if (entry === pending) return undefined;
    if (entry) return entry.proposal;
    this.load(proposalId);
    return undefined;
  };

  load = async (proposalId: string): Promise<IProposal> => {
    this.cache[proposalId] = pending;
    try {
      const response = await getRequest(`/proposals/${proposalId}`);
      if (response.status === ApiResponseStatus.Success) {
        this.cache[proposalId] = {
          at: Date.now(),
          proposal: response.data as IProposal,
        };
      }
      return response.data as IProposal;
    } catch (e: any) {
      delete this.cache[proposalId];
      throw e;
    }
  };
}
