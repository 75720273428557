import { NameValueDict } from '../types/FormTypes';
import { FieldValueType } from '../types/Field';

const BASE_URL = '/api';

export enum ApiErrorResponseMessage {
  ProposalIdRequired = 'No job id provided',
}

export enum ApiResponseStatus {
  Success = 'SUCCESS',
  Error = 'ERROR',
  Abort = 'ABORT',
}

export interface ApiSuccess<T = any> {
  status: ApiResponseStatus.Success;
  data: T;
}

export interface ServerValidationErrorDetail {
  loc: string[];
  msg: string;
  type: string;
}

export interface ServerValidationError {
  error: string;
  detail: ServerValidationErrorDetail[];
}

export interface ApiError {
  status: ApiResponseStatus.Error | ApiResponseStatus.Abort;
  data: string;
  statusCode?: number;
  payload?: ServerValidationError;
}

export type ApiResponse<T = any> = ApiSuccess<T> | ApiError;

export type SearchParams =
  | Record<string, string | number | boolean>
  | string[][]
  | FormData
  | URLSearchParams
  | null;

/**
 * Helper to build complete URLs for the Rose backend
 *
 * @param path - url subpath to call
 * @param searchParams - optional extra query string parameters to add
 */
export function buildUrl(path: string, searchParams: SearchParams = null) {
  let paramStr = null;
  let baseUrl: string | undefined = BASE_URL;
  if (typeof window === 'undefined') {
    // If we are in a server environment, we need to add the full URL
    baseUrl = process.env.ROSE_BACKEND_URL;
    if (!baseUrl) {
      if (process.env.NODE_ENV === 'development') {
        baseUrl = 'http://localhost:8000/api';
      } else {
        baseUrl = 'http://api/api';
      }
    }
  }

  if (searchParams) {
    if (Array.isArray(searchParams)) {
      paramStr = new URLSearchParams(searchParams).toString();
    } else if (searchParams instanceof FormData) {
      // From https://github.com/microsoft/TypeScript/issues/30584#issuecomment-890515551
      const convertedFormEntries = Array.from(searchParams, ([key, value]) => [key, String(value)]);

      paramStr = new URLSearchParams(convertedFormEntries).toString();
    } else if (searchParams instanceof URLSearchParams) {
      paramStr = searchParams.toString();
    } else {
      const paramsObj = new URLSearchParams();
      for (const entry of Object.entries(searchParams)) {
        const [k, v] = entry;
        if (Array.isArray(v)) {
          v.map(elem => paramsObj.append(k, elem));
        } else if (v !== undefined && v !== null) {
          paramsObj.append(k, String(v));
        }
      }
      paramStr = paramsObj.toString();
    }

    return `${baseUrl}${path}?${paramStr}`;
  }

  return `${baseUrl}${path}`;
}

export async function handleResponse<T>(response: Response) {
  // CloudFlare seems to add `Content-Type: application/json` and `Content-Length: 0` to 204 responses.
  // This breaks response.json() below.
  if (response.status === 204) {
    return { status: ApiResponseStatus.Success, data: {} };
  }

  const mime = response.headers.get('Content-Type');
  let data: any = null;
  if (mime && mime.includes('json')) {
    data = await response.json();
  }

  // unauthed errors should remove the token
  if (!response.ok && response.status === 401) {
    globalThis.Rose?.user?.setAuthData({ auth: false, cached: false });
  }

  if (data) {
    // The request was made and the server responded with an error
    if (data.errors)
      return {
        status: ApiResponseStatus.Error,
        statusCode: response.status,
        data: data.errors,
        payload: data,
      };
    if (data.error)
      return {
        status: ApiResponseStatus.Error,
        statusCode: response.status,
        data: data.error,
        payload: data,
      };
    if (!response.ok) return { status: ApiResponseStatus.Error, statusCode: response.status, data };

    return { status: ApiResponseStatus.Success, data };
  }

  const text = await response.text();
  if (!response.ok)
    return {
      status: ApiResponseStatus.Error,
      statusCode: response.status,
      data: text || 'Unknown error',
    };
  return { status: ApiResponseStatus.Success, data: response as unknown as T };
}

export async function handleError(error: TypeError) {
  // Something happened in setting up the request that triggered an Error
  // fetch fails for network related things
  return {
    status: error.name === 'AbortError' ? ApiResponseStatus.Abort : ApiResponseStatus.Error,
    data: error.message,
  };
}

export const getRequest = async <T = any>(
  path: string,
  params: SearchParams = null,
  abortController?: AbortController
) => {
  const init: RequestInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };

  if (abortController) {
    init.signal = abortController.signal;
  }

  try {
    const response = await fetch(buildUrl(path, params), init);
    return await handleResponse<T>(response);
  } catch (error: any) {
    return await handleError(error);
  }
};

export const postRequest = async <T = any>(
  path: string,
  body: unknown | NameValueDict<FieldValueType> = {},
  params: SearchParams = null,
  abortController?: AbortController
) => {
  const init: RequestInit = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': await globalThis.Rose?.user?.getCsrfToken(),
    },
    body: JSON.stringify(body),
  };

  if (abortController) {
    init.signal = abortController.signal;
  }

  try {
    const response = await fetch(buildUrl(path, params), init);

    return await handleResponse<T>(response);
  } catch (error: any) {
    return await handleError(error);
  }
};

export const patchRequest = async <T = any>(
  path: string,
  body: NameValueDict<FieldValueType> | FormData,
  params: SearchParams = null
) => {
  try {
    const response = await fetch(buildUrl(path, params), {
      method: 'PATCH',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': await globalThis.Rose?.user?.getCsrfToken(),
      },
      body:
        body instanceof FormData ? JSON.stringify(Object.fromEntries(body)) : JSON.stringify(body),
    });
    return await handleResponse<T>(response);
  } catch (error: any) {
    return await handleError(error);
  }
};

export const putRequest = async <T = any>(
  path: string,
  body: NameValueDict<any>,
  params: SearchParams = null
) => {
  try {
    const response = await fetch(buildUrl(path, params), {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': await globalThis.Rose?.user?.getCsrfToken(),
      },
      body: JSON.stringify(body),
    });
    return await handleResponse<T>(response);
  } catch (error: any) {
    return await handleError(error);
  }
};

export const deleteRequest = async <T = any>(path: string, params: SearchParams = null) => {
  try {
    const response = await fetch(buildUrl(path, params), {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': await globalThis.Rose?.user?.getCsrfToken(),
      },
    });
    return await handleResponse<T>(response);
  } catch (error: any) {
    return await handleError(error);
  }
};

export const gcsUploadFileToSignedUrl = async <T = any>(
  signedUrl: string,
  headers: Headers,
  file: File
) => {
  console.log(
    `file size: ${file.size} file type: ${file.type} uploading ${file.name} to ${signedUrl}`
  );
  try {
    const response = await fetch(signedUrl, {
      method: 'PUT',
      mode: 'cors',
      headers,
      body: file,
    });
    return await handleResponse<T>(response);
  } catch (error: any) {
    return await handleError(error);
  }
};

export const exportedForTesting = {
  buildUrl,
};
